import { useContext, useState } from 'react';
import { GoogleSignInButton } from './GoogleSigninButton';
import { ApplicationLink } from './Routing';
import { CheckboxField, FieldContainer, Message, TextField } from "./TypescriptComponents";
import { SubmitHandler, useForm } from "react-hook-form";
import { RecaptchaField } from "./ReactComponents";
import { useDoLogin } from "./Hooks";
import { FormError, RankedUser, Settings } from "./models";
import { SettingsContext } from "./Context";
import { useSearchParams } from "react-router-dom";
import { displayErrorMessage, ErrorResponse, HTTP_CLIENT } from "./Util";

interface LoginFields {
    email: string;
    password: string;
    remember: boolean;
    "g-recaptcha-response": boolean;
}

const passwordValidation = {
    required: {
        value: true,
        message: "You need to enter a password"
    }
};

const usernameValidation = {
    required: {
        value: true,
        message: "You need to enter a username"
    }
};
export const LoginUserPage = () => {
    const [errors, setErrors] = useState<Map<string, string>>(new Map<string, string>());
    const {register, handleSubmit} = useForm<LoginFields>();
    const settings = useContext<Settings | undefined>(SettingsContext);
    const login = useDoLogin()
    const [searchParams] = useSearchParams();

    const onSubmit: SubmitHandler<LoginFields> = data => {
        HTTP_CLIENT.post('/api/login', data, {}, {useGlobalErrorHandling: false})
            .then(() => {
                HTTP_CLIENT.get<RankedUser>('/api/me/rank')
                    .then((response) => {
                        login(response.data);
                    })
                    .catch(() => {
                        window.location.href = '/';
                    });
            })
            .catch((error: ErrorResponse<FormError>) => {
                if (error.response?.data.response) {
                    if (error.response.data.response.field_errors) {
                        setErrors(new Map(Object.entries(error.response.data.response.field_errors)));
                    } else {
                        displayErrorMessage(error.response.data.response.errors);
                    }
                } else {
                    displayErrorMessage("Something went wrong, please refresh the webpage");
                }
            });
    }

    const copyMap = new Map<string, string>(errors);
    if (errors) {
        for (const field in errors) {
            const value = (errors as any)[field];
            if (value.message) {
                copyMap.set(field, value.message);
            }
        }
    }

    return <>
        { searchParams.has('next') ? <Message text={ "You need to be logged in to access that page" }/> : <></> }
        <h1>Login</h1>
        <form onSubmit={ handleSubmit(onSubmit) }>
            <FieldContainer label={ "Username or Email" } error={ copyMap.get("email") }>
                <TextField registerFields={ register("email", usernameValidation) }/>
            </FieldContainer>
            <FieldContainer label={ "Password" } error={ copyMap.get("password") }>
                <TextField registerFields={ register("password", passwordValidation) } type={ "password" }/>
            </FieldContainer>
            <CheckboxField registerFields={ register("remember") } error={ copyMap.get("remember") }
                           label={ "Remember me" }/>
            <RecaptchaField registerFields={ register("g-recaptcha-response") }/>
            <input type="submit" className="button" value={ "Login" } disabled={ !settings }/>
        </form>
        <ApplicationLink href={ '/reset' }>Forgot password</ApplicationLink>
        <p>Don't have an account? <ApplicationLink href={ '/register' }>Register</ApplicationLink></p>
        <GoogleSignInButton/>
    </>
}
