import React, { useContext, useEffect, useState } from 'react';
import {
    ApplicationComponents,
    AthleteResult,
    Components,
    Race,
    Settings,
    SettingsContext
} from "@fantasy/components";
import { AthleteApp } from '@fantasy/components/src/components/AthleteApp';
import { VismaAthlete } from "./models";
import { ApplicationLink } from "@fantasy/components";
import { ColumnDef, Getter } from "@tanstack/react-table";
import { formatAmount, formatNullableScore, formatScore } from '@fantasy/components';
import { AthleteName } from "./ReactComponents.tsx";

function displayAthlete(athlete: VismaAthlete) {
    return <ApplicationLink href={`/athletes/${athlete.athlete_id}`}>
        <AthleteName athlete={athlete}/>
    </ApplicationLink>;
}

const vismaColumns: ColumnDef<VismaAthlete & { prev_race_pts: number }>[] = [
    {
        header: 'Name',
        id: 'name',
        accessorFn: (athlete: VismaAthlete) => athlete,
        cell: ({ getValue }: { getValue: Getter<VismaAthlete> }) => <><div><img src={getValue().pro_team.logo_url} /></div><div><p>{ displayAthlete(getValue()) }</p><p className={"athleteTeamName"}>{ getValue().pro_team.name }</p></div></>,
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        sortDescFirst: false,
    },
    {
        header: 'Price',
        accessorFn: (obj: VismaAthlete) => formatAmount(obj.price),
        sortingFn: (a, b) => a.original.price - b.original.price,
        sortDescFirst: true,
    },
    {
        header: 'Score',
        accessorFn: (obj: VismaAthlete) => formatScore(obj.score || 0),
        sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0),
        sortDescFirst: true,
    },
    {
        header: 'Prev race pts.',
        id: 'prev_race_pts',
        accessorFn: (obj) => formatNullableScore(obj.prev_race_pts, '-'),
        sortingFn: (a, b) => (a.original.prev_race_pts | 0) - (b.original.prev_race_pts | 0),
    },
    {
        header: '',
        accessorKey: 'trade',
        enableSorting: false,
        cell: ({ getValue }: { getValue: Getter<React.JSX.Element>} ) => getValue()
    }
];

const VismaAthletesApp = () => {
    const components = useContext<Components>(ApplicationComponents);
    const [lastResults, setLastResults] = useState<Map<number, { prev_race_pts: number }>>(new Map<number, { prev_race_pts: number }>());
    const settings = useContext<Settings | undefined>(SettingsContext);

    useEffect(() => {
        if (!settings?.current_season) {
            return;
        }
        async function fetchLastRaceResult(gender: 'm' | 'f' | 'mixed'): Promise<AthleteResult[]> {
            return components.httpService.lastScoredRace(gender)
                .then(async (race: Race) => {
                    const weekend = await components.httpService.weekend(race.weekend_id);
                    if (weekend.season === settings?.current_season) {
                        return components.httpService.raceResults(race.race_id);
                    } else {
                        return [];
                    }

                });
        }

        Promise.all([fetchLastRaceResult('m'), fetchLastRaceResult('f')])
        .then((results) => {
            setLastResults(
                results.flat().reduce((map, result) => map.set(result.athlete_id, {prev_race_pts: result.score}), new Map())
            );
        });
    }, [components.httpService, settings?.current_season]);

    return <AthleteApp columns={vismaColumns} extraData={lastResults} />
}

export default VismaAthletesApp;
