import React, { Component, useEffect, useState } from "react";
import classNames from 'classnames';
import { AxiosResponse } from "axios";
import { RankedUser, Weekend, WeekendResults } from "@fantasy/components";
import { usePageTracking } from "@fantasy/components";
import { HTTP_CLIENT } from "@fantasy/components";
import VismaSvgLogo from "./img/ski_classics_fantasy_logo.svg"
import "./scoreboard.scss"
import { HTTP_SERVICE } from "./Util";

export const VismaScoreboardApp = () => {
    const [mobile, setMobile] = useState<boolean | null>(null);
    const [overall, setOverall] = useState<RankedUser[]>([]);
    const [overallLoaded, setOverallLoaded] = useState<boolean>(false);
    const [weekendResults, setWeekendResults] = useState<WeekendResults | null>(null);
    const [weekendLoaded, setWeekendLoaded] = useState<boolean>(false);
    usePageTracking();

    function resize() {
        setMobile(window.innerWidth <= 1000);
    }
    
    useEffect(() => {
        window.addEventListener("resize", resize.bind(this));
        resize();
        HTTP_SERVICE.overallRanking(10, null).then((response: RankedUser[]) => {
            setOverall(response);
            setOverallLoaded(true);
        });
        HTTP_CLIENT.get('/api/weekends/previous/results/users').then((response: AxiosResponse<WeekendResults>) => {
            if(response.data.users.length === 0) {
                HTTP_CLIENT.get('/api/weekends/upcoming').then((response: AxiosResponse<Weekend>) => {
                    setWeekendResults({
                        weekend: response.data,
                        users: []
                    });
                    setWeekendLoaded(true);
                });
            }
            else {
                setWeekendResults(response.data);
                setWeekendLoaded(true);
            }
        });
    }, []);


    if(mobile === null || !weekendLoaded || !overallLoaded) {
        return <React.Fragment />;
    }
    let scoreboards;
    if(mobile) {
        scoreboards = <MobileScoreboards overall={overall} weekendResults={weekendResults as WeekendResults} />;
    }
    else {
        scoreboards = <DesktopScoreboards users={overall} rankedWeekend={weekendResults as WeekendResults} />;
    }
    return <div className={classNames("scoreboard container", {"desktop": !mobile}, {"mobile": mobile})}>
        <a href={'/'}>
            <div className="header mainHeader">
                <img alt='Ski classics logo' className='vismaLogo' src={VismaSvgLogo} />
                <h2>SC Fantasy Leaderboard</h2>
            </div>
        </a>
        { scoreboards }
        <a href={'/'}>
            <div className='slogan'>
                <small>Compete for prizes and glory on fantasy.skiclassics.com!</small>
            </div>
        </a>
    </div>;
}

const DesktopScoreboards = ({users, rankedWeekend}: {users: RankedUser[], rankedWeekend: WeekendResults}) => {
    return <a href={'/'}>
        <div className={"tables"}>
            <OverallScoreboard users={users} />
            <LastWeekScoreboard results={rankedWeekend} />
        </div>
    </a>
};

class MobileScoreboards extends Component<MobileScoreboardsProps, MobileScoreboardsState> {

    constructor(props: MobileScoreboardsProps) {
        super(props);
        this.state = {
            selectedTable: 'OVERALL'
        }
    }

    render() {
        let scoreTable;
        if(this.state.selectedTable === 'OVERALL') {
            const rows = this.props.overall.map((user) => userToScoreRow(user));
            scoreTable = <ScoreTable users={rows} />;
        }
        else {
            const rows = this.props.weekendResults.users.map((user) => userToScoreRow(user));
            scoreTable = <ScoreTable users={rows} />
        }
        let weekendHeader;
        if(this.props.weekendResults.users.length) {
            weekendHeader = 'Last event: ' + this.props.weekendResults.weekend.weekend_name;
        }
        else {
            weekendHeader = 'Next event: ' + this.props.weekendResults.weekend.weekend_name;
        }
        return <div className={'mobile'}>
            <div className='headers'>
                <h6 className={classNames('header', {'selected': this.state.selectedTable === 'OVERALL'})}
                    onClick={() => this.setState({selectedTable: 'OVERALL'})}>Season standing</h6>
                <h6 className={classNames('header', {'selected': this.state.selectedTable === 'WEEKEND'})}
                onClick={() => this.setState({selectedTable: 'WEEKEND'})}>{weekendHeader}</h6>
            </div>
            <a href={'/'}>
                {scoreTable}
            </a>
        </div>
    }
}

function userToScoreRow(user: RankedUser): ScoreRow {
    return {
        rank: user.rank || 0,
        name: user.user.team_name + " " + user.user.flag,
        score: user.score || null
    };
}

interface MobileScoreboardsProps {
    overall: RankedUser[]
    weekendResults: WeekendResults;
}

interface MobileScoreboardsState {
    selectedTable: string
}

class OverallScoreboard extends Component<OverallProps> {

    render() {
        const rows = this.props.users.map((user) => userToScoreRow(user));
        return <VismaScoreboard users={rows} header="Season standing" />
    }

}

interface OverallProps {
    users: RankedUser[];
}

class LastWeekScoreboard extends Component<LastWeekendProps> {

    render() {
        let weekendHeader;
        if(this.props.results.users.length) {
            weekendHeader = 'Last event: ' + this.props.results.weekend.weekend_name;
        }
        else {
            weekendHeader = 'Next event: ' + this.props.results.weekend.weekend_name;
        }
        const rows = this.props.results.users.map((user) => userToScoreRow(user));
        return <VismaScoreboard users={rows} header={weekendHeader} />
    }

}

interface LastWeekendProps {
    results: WeekendResults;
}

class VismaScoreboard extends Component<VismaScoreboardProps> {
    render() {
        return <div className={'scoreboard'}>
            <h6 className='header desktop'>{this.props.header}</h6>
            <ScoreTable users={this.props.users} />
        </div>;
    }
}

interface VismaScoreboardProps {
    users: ScoreRow[];
    header: string;
}

const ScoreTable = ({users}: {users: ScoreRow[]}) => {
    if(!users.length) {
        users = [];
        for(let i = 1; i <= 10; i++) {
            users.push({
                rank: i,
                name: '',
                score: null
            });
        }
    }
    const rows = users.map((user) => <tr key={user.name}>
        <td>{user.rank}</td><td className='teamName'>{user.name}</td><td>{user.score ? (user.score + 'p.') : ''}</td>
    </tr>);

    return <table>
        <tbody>{rows}</tbody>
    </table>
};

interface ScoreRow {
    rank: number;
    name: string;
    score: number | null;
}
