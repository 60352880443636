import { HTTP_CLIENT, HttpService, RankedUser } from "@fantasy/components";

class VismaHttpService extends HttpService {
  async overallRanking(limit: number, verified_users: boolean | null = false): Promise<RankedUser[]> {
    const params: { limit: number, verified_users?: boolean } = { limit };
    if(verified_users !== null) {
      params['verified_users'] = verified_users
    }
    return HTTP_CLIENT.get<RankedUser[]>(`/api/users`, { params })
    .then((response) => response.data);
  }
}

export const HTTP_SERVICE = new VismaHttpService();
