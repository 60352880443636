import React, { useContext, useEffect, useState } from "react"
import { Athlete, AthletePopularity, AthletePrice, OwnedAthlete, Race, RankedUser, Selection, Weekend } from "./models";
import { ApplicationLink } from "./Routing";
import Skeleton from "react-loading-skeleton"
import { Flag, Table } from "./ReactComponents";
import { ApplicationComponents, Components } from "./Context";
import { useRedirectToLoginIfNeeded } from "./Hooks";
import {
    calculatePPG,
    formatNullableAmount,
    formatNullableRank,
    formatNullableScore,
    formatPPG,
    formatRank,
    formatScore,
    HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER
} from "./Util";
import { Accordion, AccordionObject } from "./TypescriptComponents";
import "./scss/weekends.scss";
import { useLoaderData } from "react-router-dom";
import { LoadingContainer, ReactTable } from "./SharedComponents";
import { ColumnDef, Getter } from "@tanstack/react-table";

interface SelectionSectionProps {
    selection: Selection,
    index: number,
    isWeekendsOnlySelection?: boolean
}

function formatRace(race: Race) {
    const genderMap = new Map<string, string>([['f', 'Women\'s'], ['m', 'Men\'s'], ['mixed', 'Mixed']]);
    return `${genderMap.get(race.gender)} ${race.distance || ''} ${race.discipline || ''} ${race.name}`;
}

const SelectionSection = ({selection, index, isWeekendsOnlySelection=false}: SelectionSectionProps) => {
    const deadlineInfo = `Trading deadline: ${new Date(selection.trading_close).toLocaleString(undefined, {timeZoneName: "short"})}`;

    return <>
        <h5>{ isWeekendsOnlySelection ? deadlineInfo : `Team Selection #${index + 1} (${deadlineInfo})` }</h5>
        <table className="table">
            <tbody>
                { selection.races.map(race =>
                    <tr key={race.race_id}>
                        <td>{race.date}</td>
                        <td>
                            <ApplicationLink class="link" href={ `/races/${race.race_id}` }>
                                { formatRace(race) }
                            </ApplicationLink>
                        </td>
                    </tr>
                ) }
            </tbody>
        </table>
    </>
}

export const WeekendsPage = () => {
    const [weekends, setWeekends] = useState<Weekend[] | undefined>();
    const [displayedWeekendId, setDisplayedWeekendId] = useState<number | undefined>();
    const getWeekendTitle = (weekend: Weekend) => {
        const countries = Array.from(new Set(weekend.selections.map((selection: Selection) => selection.races.map((race: Race) => race.country)).flat()));

        return <>
            <div className="weekend-accordion-header">
                <ApplicationLink href={ `/events/${weekend.weekend_id}` }>{ weekend.weekend_name }</ApplicationLink>
                { countries.map((country) => <Flag key={ country } country={country}/>) }
            </div>
            <div className="weekend-accordion-subheader">
                { weekend.selections.reduce((prev, cur) => prev + cur.races.length, 0) } Races&nbsp;|&nbsp;
                { weekend.event_start } - { weekend.event_end }
            </div>
        </>
    }

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/weekends?season=current'
        }).then((weekends: Weekend[]) => {
            weekends = weekends
                .filter(weekend => !weekend.hidden)
                .sort((a, b) => a.event_start.localeCompare(b.event_start));
            setWeekends(weekends);
            let upcoming_weekend = weekends.find((weekend) => weekend.is_upcoming);
            if (upcoming_weekend) {
                setDisplayedWeekendId(upcoming_weekend.weekend_id);
            }
        });
    }, []);

    const loadingSkeleton = Array.from(Array(10)).map((_, index) => <LoadingWeekendElement key={ index } />);
    const weekendElements = weekends?.map((weekend) =>
        <AccordionObject key={ weekend.weekend_id } name={getWeekendTitle(weekend)} active={displayedWeekendId == weekend.weekend_id} select={() => setDisplayedWeekendId(weekend.weekend_id)}>
            {
                weekend.selections
                    .sort((a, b) => a.trading_close.localeCompare(b.trading_close))
                    .map((selection, index) =>
                        <SelectionSection key={ selection.selection_id }
                                          selection={selection}
                                          index={index}
                                          isWeekendsOnlySelection={weekend.selections.length === 1}
                        />)
            }
        </AccordionObject>
    );

    return <div>
        <header className="entry-header">
            <h1 className="entry-title">Weekends & Tours</h1>
        </header>
        <p>Download all trading deadlines as an <a href="/events.ics">.ics file</a></p>
        <Accordion>
            {weekendElements || loadingSkeleton}
        </Accordion>
    </div>
}

const LoadingWeekendElement = () => {
    const getTitle = () => <>
        <div className="weekend-accordion-header"><Skeleton /></div>
        <div className="weekend-accordion-subheader"><Skeleton /></div>
    </>

    return <AccordionObject name={getTitle()} active={false}>&nbsp;</AccordionObject>
}

export const WeekendPage = () => {

    const weekend = useLoaderData() as Weekend;

    return <>
        <h1>{ weekend?.weekend_name || <Skeleton/> }</h1>
        <div className="row">
            <div className="medium-6 columns">
                <WeekendInformationTable weekend={weekend} />
            </div>
            <div className="medium-6 columns">
                <UserTable weekend={weekend} />
            </div>
        </div>
        <div className="row">
            <div className="medium-12 columns">
                <AthleteTable weekend={weekend} />
            </div>
        </div>
    </>
}

export const WeekendInformationTable = ({weekend}: {weekend?: Weekend}) => {
    const [userScore, setUserScore] = useState<WeekendResult | undefined>();

    useRedirectToLoginIfNeeded();
    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<WeekendResult>(`/api/me/weekend_results/${weekend.weekend_id}`)
                .then((response) => setUserScore(response.data))
        }
    }, [weekend]);

    const data: {title: string | React.JSX.Element, value: any, hasValue?: boolean, settings?: any}[] = [
        {title: 'Event Starts', value: weekend?.event_start},
        {title: 'Event Ends', value: weekend?.event_end},
        {title: 'My weekend score', value: formatNullableScore(userScore?.score, 'N/A')},
        {title: 'My weekend rank', value: formatNullableRank(userScore?.weekend_rank, 'N/A')},
    ];

    const columns = [
        {
            dataFunction: (data: any) => { return {
                content: data.title,
            }},
            headerKey: 'category'
        },
        {
            dataFunction: (data: any) => { return {
                isLoading: data.value === undefined,
                content: data.value,
            }},
            headerKey: 'value'
        }
    ];

    return <React.Fragment>
        <h2>Weekend Information</h2>
        <Table config={{hasHeaders: false}} data={data} columns={columns} />
        <h2>Races</h2>
        <table className="table">
            <tbody>
                {
                    weekend?.selections.map(selection => selection.races.map(race =>
                        <tr>
                            <td>{ race.date }</td>
                            <td>{ <ApplicationLink href={ `/races/${race?.race_id}` }>{ formatRace(race) }</ApplicationLink> }</td>
                            <td>
                                { race.scored && <ApplicationLink class="button" href={ `/races/${race?.race_id}` }>Results</ApplicationLink> }
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </React.Fragment>
}

interface WeekendResult {
    score?: number,
    weekend_rank?: number,
    user_id: number,
    weekend_id: number,
    "weekend_name": string
}

export const UserTable = ({ weekend }: { weekend?: Weekend }) => {

    const [bestUsers, setBestUsers] = useState<RankedUser[] | undefined>();
    const {userDisplay} = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<{users: RankedUser[]}>(`/api/weekends/${weekend.weekend_id}/results/users`)
                .then((response) => setBestUsers(response.data.users));
        }
    }, [weekend]);

    const columns = [
        {
            dataFunction: (data: RankedUser) => { return {
                content: formatNullableRank(data.rank, 'N/A'),
            }},
            headerKey: 'user rank'
        },
        {
            dataFunction: (data: RankedUser) => { return {
                content: userDisplay(data.user),
            }},
            headerKey: 'user name'
        },
        {
            dataFunction: (data: RankedUser) => { return {
                content: formatNullableScore(data.score, '-'),
            }},
            headerKey: 'user score'
        },
    ];
    return <React.Fragment>
        <h2>Top Players</h2>
        <Table config={{hasHeaders: false}} columns={columns} data={bestUsers} />
    </React.Fragment>
}

const AthleteTable = ({ weekend }: { weekend?: Weekend}) => {
    const [usersTeam, setUsersTeam] = useState<OwnedAthlete[]>([]);
    const [bestAthletes, setBestAthletes] = useState<Athlete[] | undefined>();
    const [athletePrices, setAthletePrices] = useState<Map<number, number> | undefined>();
    const [athletePopularities, setAthletePopularities] = useState<Map<number, number> | undefined>();
    const {athleteDisplay} = useContext<Components>(ApplicationComponents);

    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<Athlete[]>(`/api/weekends/${weekend.weekend_id}/results/athletes`)
                .then((race) => {
                    setBestAthletes(race.data)
                });

            weekend.selections.forEach((selection) => {
                HTTP_CLIENT.get<OwnedAthlete[]>(`/api/me/teams/selections/${selection.selection_id}`)
                    .then((team) => {
                        setUsersTeam((currentTeam) => [...currentTeam].concat(team.data))
                    });
            });

            if (!weekend.trading_open && weekend.selections.length === 1) {
                HTTP_CLIENT.get<AthletePopularity[]>(weekend.selections[0].links.athlete_popularity)
                    .then((response) => {
                        const athletePopularities = response.data.reduce((selectionPopularities, popularity) =>
                            selectionPopularities.set(popularity.athlete_id, popularity.ratio), new Map<number, number>());
                        setAthletePopularities(athletePopularities);

                    });
            }

            HTTP_CLIENT.get<AthletePrice[]>(weekend.links.prices)
                .then((response) => {
                    const weekend_prices = response.data.reduce((prices, price) =>
                        prices.set(price.athlete_id, price.price), new Map<number, number>());
                    setAthletePrices(weekend_prices);
                });
        }
    }, [weekend]);

    const columns: ColumnDef<Athlete>[] = [
        {
            accessorFn: (data: Athlete) => formatRank(data.rank),
            header: '',
            id: 'rank'
        },
        {
            accessorFn: (data: Athlete) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => athleteDisplay(getValue()),
            header: 'Name',
            id: 'name',
            enableSorting: false
        },
        {
            accessorFn: (data: Athlete) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => formatScore(getValue().score),
            header: 'Weekend score',
            id: 'score'
        },
        {
            accessorFn: (data: Athlete) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => formatNullableAmount(athletePrices?.get(getValue().athlete_id),  "-"),
            header: 'Price',
            id: 'price',
            sortingFn: (a, b) => {
                if (!athletePrices) {
                    return 0;
                }
                let aPrice = athletePrices.get(a.original.athlete_id) || 0;
                let bPrice = athletePrices.get(b.original.athlete_id) || 0;
                return aPrice - bPrice;
            }
        },
        {
            accessorFn: (data: Athlete) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => <LoadingContainer data={athletePrices} mapper={(prices) => formatPPG(calculatePPG(getValue(), prices, getValue().score))} />,
            header: 'PPG',
            id: 'ppg',
            sortingFn: (a, b) => {
                if (!athletePrices) {
                    return 0;
                }
                let aPPG = calculatePPG(a.original, athletePrices, a.original.score);
                let bPPG = calculatePPG(b.original, athletePrices, b.original.score);
                if (aPPG === undefined && bPPG === undefined) {
                    return 0;
                }
                if (aPPG === undefined) {
                    return 1;
                }
                if (bPPG === undefined) {
                    return 1;
                }
                return aPPG - bPPG;
            }
        },
        {
            accessorFn: (data: Athlete) => data,
            cell: ({ getValue }: { getValue: Getter<Athlete>}) => {
                const athlete = getValue();
                const athleteInTeamCount = usersTeam?.filter((userAthlete) => userAthlete.athlete.athlete_id === athlete.athlete_id).length;
                const matchingSelections = weekend?.selections.filter(
                    (selection) => selection.takes_team_picks === athlete.is_team && selection.is_coed === (athlete.gender === 'mixed')).length

                let inMyTeamString;

                if (athleteInTeamCount) {
                    inMyTeamString =  '✔';

                    if (matchingSelections && matchingSelections > 1) {
                        inMyTeamString += ` (${athleteInTeamCount}/${matchingSelections})`;
                    }
                } else {
                    inMyTeamString = ' ';
                }

                return inMyTeamString
            },
            header: 'In my team?',
            id: 'team-indicator',
            enableSorting: false
        }
    ];

    if(weekend?.selections.find((selection) => selection.team_captains_enabled)) {
        columns.push(
            {
                accessorFn: (data: Athlete) => data,
                cell: ({ getValue }: { getValue: Getter<Athlete>}) =>
                    <LoadingContainer data={usersTeam} mapper={(team) => team.find((userAthlete) => userAthlete.athlete.athlete_id === getValue().athlete_id && userAthlete.is_team_captain) ? '✔' : ' '} />
                ,
                header: 'Captain',
                enableSorting: false
            })
    }

    if (weekend && weekend?.selections.length === 1) {
        columns.push(
            {
                accessorFn: (data: Athlete) => data,
                cell: ({ getValue }: { getValue: Getter<Athlete>}) =>
                    <LoadingContainer data={athletePopularities} mapper={(popularities) => `${Math.round((popularities.get(getValue().athlete_id) || 0) * 100)}%`} />,
                header: 'Popularity',
                sortingFn: (a, b) => {
                    if (!athletePopularities) {
                        return 0;
                    }
                    let aPopularity = athletePopularities.get(a.original.athlete_id) || 0;
                    let bPopularity = athletePopularities.get(b.original.athlete_id) || 0;
                    return aPopularity - bPopularity;
                }
            }
        )
    }

    return <React.Fragment>
        <h2>Athlete results</h2>
        <ReactTable columns={columns} data={bestAthletes || []} pageSize={20} />
    </React.Fragment>
}
