import { ApplicationLink, ServerError, SiteBase } from '@fantasy/components';
import { VismaMainMenu } from './MainMenu';
import React from 'react';
import VismaSvgLogo from "./img/ski_classics_fantasy_logo.svg"

export const VismaMainPage = () =>
    <SiteBase mainMenu={<VismaMainMenu />} footer={<SiteFooter />} subFooter={<React.Fragment />} errorPage={<ServerError />} logo={<VismaLogo />} />;

const SiteFooter = () => <footer>
    <div id="sitemap">
        <div className="row">
            <div className="medium-3 columns">
                <h4>Play the game</h4>
                <ul>
                    <li><ApplicationLink href={ '/info/rules' }>How to play</ApplicationLink></li>
                </ul>
            </div>
            <div className="medium-3 columns">
                <h4>Standings</h4>
                <ul>
                    <li><ApplicationLink href={ '/users/me' }>My Team</ApplicationLink></li>
                    <li><ApplicationLink href={ '/overall' }>Overall player ranking</ApplicationLink></li>
                    <li><ApplicationLink href={ '/leagues' }>Leagues</ApplicationLink></li>
                    <li><ApplicationLink href={ '/athletes' }>Athletes</ApplicationLink></li>
                </ul>
            </div>
            <div className="medium-3 columns">
                <h4>Schedule</h4>
                <ul>
                    <li><ApplicationLink href={ '/events' }>Events</ApplicationLink></li>
                </ul>
            </div>
            <div className="medium-3 columns">
                <h4>Get in touch</h4>
                <ul>
                    <li><ApplicationLink href={ '/info/contact' }>Contact</ApplicationLink></li>
                </ul>
            </div>
        </div>
    </div>
</footer>;

const VismaLogo = () => <div className="logo">
    <ApplicationLink href={'/'}>
        <img src={VismaSvgLogo} alt="home"/>
    </ApplicationLink>
</div>
