import { Params } from "react-router-dom";
import {
    Athlete,
    League, LeagueScore,
    LeagueWeekendResult, LeagueWeekendViewLoaderData, LoadedLeague,
    LoadedRaceData,
    LoadedUser,
    Race,
    RankedUser,
    Selection,
    Weekend
} from "./models";
import { requestData } from "./Util";

export const athleteLoader = async ({ params }: {params: Params<string>}): Promise<Athlete> => {
    return await requestData<Athlete>(`/api/athletes/${params.athleteId}`);
}

export const userLoader = async ({ params }: {params: Params<string>}): Promise<LoadedUser> => {
    const user = await requestData<RankedUser>(`/api/users/${params.userId}/rank`);
    const usersLeagues = await requestData<League[]>(`/api/users/${params.userId}/leagues`);
    return {user, usersLeagues}
};

export const weekendLoader = async ({ params }: {params: Params<string>}): Promise<Weekend> => {
    return await requestData<Weekend>(`/api/weekends/${params.weekendId}`)
};

export const raceLoader = async ({ params }: {params: Params<string>}): Promise<LoadedRaceData> => {
    const race = await requestData<Race>(`/api/races/${params.raceId}`);
    const weekend = await requestData<Weekend>(race.links.weekend);
    const selection = await requestData<Selection>(race.links.selection);

    return { race, selection, weekend };
}

export const leagueWeekendLoader = async ({ params }: {params: Params<string>}): Promise<LeagueWeekendViewLoaderData> => {
    const weekend = await requestData<Weekend>(`/api/weekends/${params.weekendId}`);
    const leagueMembers = await requestData<RankedUser[]>(`/api/leagues/${encodeURIComponent(params.leagueName as string)}/members/`);
    const result = await requestData<LeagueWeekendResult>(`/api/leagues/${encodeURIComponent(params.leagueName as string)}/results/${params.weekendId}`);
    return {weekend, leagueMembers, result}
};

export const singleLeagueLoader = async ({ params }: {params: Params<string>}): Promise<LoadedLeague> => {
    const league = requestData<League>(`/api/leagues/${params.leagueName}`);
    const leagueScore = requestData<LeagueScore>(`/api/leagues/${params.leagueName}/score`);
    const leagueMembers = requestData<RankedUser[]>(`/api/leagues/${encodeURIComponent(params.leagueName as string)}/members/`);

    return { league: await league, leagueScore: await leagueScore, leagueMembers: await leagueMembers };
};
