import "./_settings.scss"
import "./index.scss"

import { createRoot } from 'react-dom/client';
import React from "react";
import { VismaMainPage } from "./Base";
import { VismaRegisterUserPage } from "./VismaRegisterPage";
import { VismaMyUserPage, VismaUserPage } from "./UserApp";
import { ApplicationRouting, CopyPage, Race, Routes, Selection, Weekend } from "@fantasy/components";
import VismaAthletesApp from "./VismaAthletesApp";
import { VismaAthletePage } from "./VismaAthleteApp";
import { WeekendPage, WeekendsPage } from "./VismaWeekendsPage";
import { AthleteTable } from "./RaceApp";
import { ApplicationComponentProvider } from "./Provider";
import { VismaScoreboardApp } from "./VismaScoreboardApp";
import { IndexPage } from "./IndexPage";
import { RouteObject, RouterProvider, } from "react-router-dom";
import { RacePage } from "@fantasy/components/src/components/RaceApp";
import { athleteLoader, raceLoader, userLoader } from "../../components/src/components/loaders.ts";
import { vismaWeekendLoader } from "./loaders.ts";

class VismaRouting extends Routes {
    getBaseElement() {
        return <VismaMainPage/>
    }

    getRegisterView() {
        return <VismaRegisterUserPage />;
    }

    getUserPage() {
        return {
            element: <VismaUserPage/>,
            loader: userLoader
        }
    }

    getMyUserPage() {
        return <VismaMyUserPage />
    }

    getAthletesElement() {
        return <VismaAthletesApp />;
    }

    getEventsPage() {
        return <WeekendsPage />;
    }

    appSpecificProviders(children: JSX.Element) {
        return <ApplicationComponentProvider>{children}</ApplicationComponentProvider>;
    }

    getRacePage() {
        return {
            element: <RacePage athleteTable={(race?: Race, selection?: Selection, weekend?: Weekend) =>
                <AthleteTable race={race} weekend={weekend} /> } />,
            loader: raceLoader
        }
    }

    getAthletePage() {
        return {
            element: <VismaAthletePage />,
            loader: athleteLoader
        }
    }

    getIndexPage() {
        return <IndexPage />;
    }

    getEventPage() {
        return {
            element: <WeekendPage />,
            loader: vismaWeekendLoader
        }
    }

    appSpecificViews(): RouteObject[] {
        return [
            {
                path: "info",
                children: [
                    {
                        path: 'contact',
                        element: <CopyPage />,
                        children: [],
                    },
                    {
                        path: 'rules',
                        element: <CopyPage />,
                        children: [],
                    }
                ]
            }
        ];
    }

    extraRoutes(): RouteObject[] {
        return [{
            path: "scoreboard",
            element: <VismaScoreboardApp />,
            children: []
        }];
    }
}


const routing = new VismaRouting();
createRoot(document.getElementById("root") as Element).render(
    <React.StrictMode>
        <RouterProvider router={ApplicationRouting({data: routing})} />
    </React.StrictMode>,
);
