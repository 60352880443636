import { useContext, useEffect, useState } from "react"
import {
    ApplicationComponents,
    CountryContext,
    CupContext, ErrorContext,
    SettingsContext,
    UserContext
} from "./Context";
import { Country, Cup, RankedUser, ServerError, Settings, User } from "./models";
import { AthleteDisplay, Flag, UserDisplay } from "./ReactComponents";
import { ErrorResponse, HTTP_CLIENT, HTTP_CLIENT_JQUERY_ADAPTER, HttpService } from "./Util";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";

export const CountryProvider = (props: ProviderProps) => {

    const [countries, setCountries] = useState<Country[] | undefined>();
    const [countryMap, setCountryMap] = useState<Map<string, Country> | undefined>();

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/countries',
            success: (countries: Country[]) => {
                setCountries(countries);
            }
        });
    }, []);

    useEffect(() => {
        if(countries) {
            const countryMap = new Map<string, Country>();
            countries.forEach((country) => countryMap.set(country.alpha_3, country));
            setCountryMap(countryMap);
        }
    }, [countries])

    return <CountryContext.Provider value={{countries, countriesByAlpha3: countryMap}}>
        {props.children}
    </CountryContext.Provider>
};

export const CupProvider = (props: ProviderProps) => {

    const [cups, setCups] = useState<Cup[]>([]);

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/cups',
            success: (cups: Cup[]) => {
                setCups(cups);
            }
        });
    }, []);

    return <CupContext.Provider value={cups}>
        {props.children}
    </CupContext.Provider>
};

export const SettingsProvider = (props: ProviderProps) => {

    const [settings, setSettings] = useState<Settings | undefined>();

    function fetchSettings() {
        if (!document.hidden) {
            HTTP_CLIENT.get<Settings>("/api/settings")
                .then((response) => {
                        setSettings(response.data);
                    }
                );
        }
    }

    useEffect(() => {
        fetchSettings();
        setInterval(fetchSettings, 1000 * 60 * 20);
        document.addEventListener("visibilitychange", () => {
            fetchSettings();
        });
    }, []);

    return <SettingsContext.Provider value={settings}>
        {props.children}
    </SettingsContext.Provider>
};

export const ApplicationComponentProvider = (props: ProviderProps) => (<ApplicationComponents.Provider value={ {
    flag: (country) => <Flag country={ country }/>,
    userDisplay: (country) => <UserDisplay user={ country }/>,
    athleteDisplay: (athlete) => <AthleteDisplay athlete={athlete} />,
    httpService: new HttpService(),
} }>
    {props.children}
</ApplicationComponents.Provider>);

export const RecaptchaProvider = (props: ProviderProps) => {
    const settings = useContext<Settings | undefined>(SettingsContext);
    if(!settings) {
        return <></>;
    }
    return <GoogleReCaptchaProvider reCaptchaKey={ settings.recaptcha_public_key }>
        {props.children}
    </GoogleReCaptchaProvider>
}

export const UserProvider = (props: ProviderProps) => {
    const [user, setUser] = useState<RankedUser | undefined>();
    const [loggedIn, setLoggedIn] = useState<boolean | undefined>();
    const [tabIsVisible, setTabIsVisible] = useState<boolean>(!document.hidden);
    const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | number | undefined>();
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);

    const shouldRefresh = loggedIn && tabIsVisible;

    function fetchUser() {
        HTTP_CLIENT.get<RankedUser>('/api/me/rank', {useGlobalErrorHandling: false})
            .then((response) => {
                setUser(response.data);
                setLoggedIn(true);
                setLastUpdate(new Date());
            })
            .catch(() => {
                setLoggedIn(false);
            });
    }

    useEffect(() => {

        if(shouldRefresh || !lastUpdate) {
            const updateInterval = 1000 * 60 * 5 - (new Date().getTime() - (lastUpdate?.getTime() || Number.MAX_VALUE));
            setIntervalRef(
                setTimeout(() => {
                    fetchUser();
                }, Math.max(updateInterval, 0))
            );
        }
    }, [shouldRefresh, lastUpdate]);

    useEffect(() => {
        if (!shouldRefresh && !!lastUpdate) {
            clearInterval(intervalRef);
            setIntervalRef(undefined);
        }
    }, [shouldRefresh, intervalRef, lastUpdate]);

    useEffect(() => {
        const listener = () => {
            if (document.hidden) {
                setTabIsVisible(false);
            } else {
                setTabIsVisible(true);
            }
        };
        document.addEventListener("visibilitychange", listener);
        return () => {
            document.removeEventListener("visibilitychange", listener)
        }
    }, []);

    if(loggedIn === undefined) {
        return <></>;
    }

    const context = {
        loggedInState: {user, loggedIn},
        updateUser: (update: Partial<User>) => {
            if(user) {
                const copiedUser = Object.assign({}, user);
                Object.assign(copiedUser.user, update);
                setUser(copiedUser)
            }
        },
        loginUser: (user: RankedUser) => {
            setUser(user);
            setLoggedIn(true);
        },
        logoutUser: () => {
            setUser(undefined);
            setLoggedIn(false);
        }
    };

    return <UserContext.Provider value={context}>
        {props.children}
    </UserContext.Provider>;
}

export const ErrorStateProvider = (props: ProviderProps) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        HTTP_CLIENT.addErrorHandling<ServerError>((error: ErrorResponse<any>) => {
            if(error.isServerError()) {
                setHasError(true);
            }
        }, false);
    }, []);

    useEffect(() => {
        setHasError(false);
    }, [location]);

    return <ErrorContext.Provider value={{
        hasError,
        displayError: () => {
            setHasError(true)
        }
    }}>
        {props.children}
    </ErrorContext.Provider>;
}

export interface ProviderProps {
    children: JSX.Element
}
