import React, { useContext, useEffect, useState } from "react";
import { CupScores, OwnedVismaAthlete } from "./models";
import {
    ApplicationComponents,
    Athlete,
    AthletePrice,
    Components,
    formatNullableScore,
    formatRank,
    formatScore,
    HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER,
    Race,
    Table,
    Weekend
} from "@fantasy/components";

export const AthleteTable = ({ race, weekend }: { race?: Race, weekend?: Weekend }) => {
    const [usersTeam, setUsersTeam] = useState<OwnedVismaAthlete[] | undefined>();
    const [athletePrices, setAthletePrices] = useState<Map<number, number> | undefined>();
    const [cupScores, setCupScores] = useState<CupScores[]>();
    const {athleteDisplay} = useContext<Components>(ApplicationComponents);

    const ownedAthleteIds = usersTeam?.map((ownedAthlete) => ownedAthlete.athlete_id);
    const teamCaptain = usersTeam?.filter((ownedAthlete) => ownedAthlete.is_team_captain)[0];

    const bestYouthinTeam = cupScores?.filter((result) => result.athlete.youth)
        .filter((result) => ownedAthleteIds?.includes(result.athlete_id))
        .sort((a, b) => b.youth - a.youth)[0];

    useEffect(() => {
        if(race) {
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/me/teams/selections/${race.selection_id}`
            }).then((team) => setUsersTeam(team));
            HTTP_CLIENT_JQUERY_ADAPTER.get({
                url: `/api/races/${race.race_id}/cups`
            }).then((cupScores: CupScores[]) => {
                setCupScores(cupScores)
            });
        }
    }, [race]);

    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<AthletePrice[]>(weekend.links.prices)
                .then((response) => {
                    const weekend_prices = response.data.reduce((prices, price) =>
                        prices.set(price.athlete_id, price.price), new Map<number, number>());
                    setAthletePrices(weekend_prices);
                });
        }
    }, [weekend]);

    function calculatePPG(athlete: CupScores): number | string {
        const athletePrice = athletePrices?.get(athlete.athlete_id);
        if(!athletePrice) {
            return '∞'
        }
        return Math.round((athlete.total * 100000 / athletePrice)) / 100.0;
    }

    function isTeamCaptain(athlete: Athlete): boolean {
        return teamCaptain?.athlete_id === athlete.athlete_id;
    }

    function isBestYouthInTeam(athlete: Athlete): boolean {
        return bestYouthinTeam?.athlete_id === athlete.athlete_id;
    }

    const columns = [
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatRank(data.rank),
            }},
            header: '',
            headerKey: 'rank'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: athleteDisplay(data.athlete),
            }},
            header: '',
            headerKey: 'athlete'
        },
        {
            dataFunction: (data: CupScores) => {
                return {
                    isLoading: false,
                    content: formatScore(data.total),
                }},
            header: 'Total'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatNullableScore(data.champion, 0),
            }},
            header: 'Champion'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatNullableScore(data.climb, 0),
            }},
            header: 'Climb'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatNullableScore(data.sprint, 0),
            }},
            header: 'Sprint'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatNullableScore(data.youth, 0),
            }},
            header: 'Youth'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: false,
                content: formatNullableScore(data.qci, 0),
            }},
            header: 'QCI'
        },
        {
            dataFunction: (data: CupScores) => { return {
                isLoading: athletePrices === undefined,
                content: calculatePPG(data),
            }},
            header: 'PPG'
        },
        {
            dataFunction: (athlete: CupScores) => {
                const bonuses = [];
                let bonusString = '';

                if (isTeamCaptain(athlete.athlete)) {
                    bonuses.push(`+${formatScore(athlete.champion / 2)} Team Captain Bonus`)
                }

                if (isBestYouthInTeam(athlete.athlete)) {
                    bonuses.push(`+${formatScore(athlete.youth)} Best Youth Bonus`)
                }

                if (bonuses.length) {
                    bonusString = ` (${bonuses.join(', ')})`
                }

                return {
                    isLoading: usersTeam === undefined,
                    content: usersTeam?.find((userAthlete) => userAthlete.athlete_id === athlete.athlete_id) ? '✔' + bonusString : ' ',
                }
            },
            header: 'In my team?'
        }
    ];

    return <React.Fragment>
        <h2>Race Results</h2>
        <Table columns={columns} data={cupScores} config={{loadingRows: 30}} />
    </React.Fragment>
}
