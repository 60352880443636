import React, { useContext } from "react";
import {
    ApplicationComponents,
    Components,
    Context,
    UserContext
} from "@fantasy/components";
import { User } from "@fantasy/components";
import { ApplicationLink } from "@fantasy/components";
import { Flag, LeaderBibs } from "@fantasy/components";
import { ProTeam, VismaAthlete, VismaUser } from "./models";
import "./logo.scss"


export const UserDisplay = ({user}: {user: User}) => {
    const userContext = useContext<Context>(UserContext);
    const components = useContext<Components>(ApplicationComponents);
    let teamName;
    if(userContext.loggedInState.user?.user_id === user.user_id) {
        teamName = <strong>{user.team_name}</strong>
    }
    else {
        teamName = <span>{user.team_name}</span>
    }

    return <ApplicationLink href={ `/users/${user.user_id}` }>
        { components.flag(user.country) }
        <LeaderBibs user={user} /> {teamName}
        {(user as VismaUser).verified_user &&
            <img style={{height: '28px'}} src={'/static/img/verified-skier.svg'} alt={"Verified user"}/>}

    </ApplicationLink>
}

export const VismaFlag = ({country}: {country: string}) => {
    if(country === 'RUS') {
        return <Flag />
    }

    return <Flag country={country} />
}

export const AthleteDisplay = ({athlete}: {athlete: VismaAthlete}) => {
    return <ApplicationLink href={ `/athletes/${athlete.athlete_id}` } class={'athlete-link'}>
        <TeamLogo team={athlete.pro_team} />
        <AthleteName athlete={athlete} />
    </ApplicationLink>
}

export const AthleteName = ({athlete}: {athlete: VismaAthlete}) => <>
    <span className={"athlete-name"}>{athlete.name}</span>
    {athlete.youth && <YouthIndicator /> || undefined}
    {athlete.is_qci_bonus_eligible && <QCIIndicator /> || undefined}
</>

export const TeamLogo = ({team}: {team: ProTeam}) => {
    return <img className="teamLogo" src={team?.logo_url} alt={""} />;
}

export const YouthIndicator = () => <span className="u27">&nbsp;Y</span>;

export const QCIIndicator = () => <span className="qci-bonus-eligible">&nbsp;QCI</span>;

