import { Params } from "react-router-dom";
import { Weekend } from "@fantasy/components";
import { requestData } from "@fantasy/components/src/components/Util.ts";
import { weekendLoader } from "@fantasy/components/src/components/loaders.ts";


export const vismaWeekendLoader = async ({ params }: {params: Params<string>}): Promise<any> => {

    return Promise.all([
        weekendLoader({params}),
        requestData<Weekend>(`/api/weekends/${params.weekendId}/cups`)
    ]);
};
