import React, { useContext, useEffect, useState } from "react";
import {
  ApplicationLink,
  Context,
  HTTP_CLIENT,
  RankedUser,
  TooltipComponent,
  UserContext,
  Weekend
} from "@fantasy/components";
import { LeaderBoard } from "@fantasy/components/src/components/IndexPage";

export const IndexPage = () => {

  const [weekend, setWeekend] = useState<Weekend | null>(null);

  useEffect(() => {

    HTTP_CLIENT.get<Weekend>(`/api/weekends/previous`)
    .then((response) => {
      setWeekend(response.data);
    });
  }, [])

  const context = useContext<Context>(UserContext);
  let loginLinks;
  if (!context.loggedInState.loggedIn) {
    loginLinks = <div className="intro">
      <p>If you've played before, <ApplicationLink href={'/login'}>login here</ApplicationLink>.</p>
      <p>If you're new, <ApplicationLink href={'/register'}>register here</ApplicationLink>.</p>
    </div>;
  }
  return <div>
    <h1>Welcome! Velkommen! Välkommen!</h1>
    {loginLinks}
    <h2 className="entry-title">Leaderboards</h2>
    <div className="row">
      <div className="medium-12 columns leaderboard-header">
        <h3>Ranked</h3>
          <span className="info-icon fi-info">
            <TooltipComponent text={"Players like you that are competing for prizes"} />
          </span>
      </div>
    </div>
    <div className="row">
      <div className="medium-6 columns">
        <TopPlayers url={'/api/users?verified_users=False&limit=10'} />
      </div>
      <div className="medium-6 columns">
        <LastWeekend weekend={weekend} urlFunction={(weekend) => `/api/users?verified_users=False&limit=10&weekend=${weekend.weekend_id}`} />
      </div>
    </div>
    <div className="row">
      <div className="medium-12 columns leaderboard-header">
        <h3>Overall</h3>
        <span className="info-icon fi-info">
            <TooltipComponent text={"Scoreboard including athletes competing for glory and bragging rights"} />
          </span>
      </div>
    </div>
    <div className="row">
      <div className="medium-6 columns">
        <TopPlayers url={'/api/users?limit=10'} />
      </div>
      <div className="medium-6 columns">
        <LastWeekend weekend={weekend} urlFunction={(weekend) => `/api/users?limit=10&weekend=${weekend.weekend_id}`} />
      </div>
    </div>
  </div>
}

export const TopPlayers = ({url}: {url: string}) => {

  const [teams, setTeams] = useState<RankedUser[]>([]);

  useEffect(() => {
    HTTP_CLIENT.get<RankedUser[]>(url)
    .then((response) => {
      setTeams(response.data);
    })
  }, [url]);

  if (!teams) {
    return <></>;
  }

  return <LeaderBoard title={'Season leaders'} elements={teams} />
}

const LastWeekend = ({weekend, urlFunction}: {weekend: Weekend | null, urlFunction: (weekend: Weekend) => string }) => {

  const [teams, setTeams] = useState<RankedUser[]>([]);

  useEffect(() => {
    if(weekend) {
      HTTP_CLIENT.get<RankedUser[]>(urlFunction(weekend))
      .then((resultResponse) => {
        setTeams(resultResponse.data);
      })
    }
  }, [weekend, urlFunction]);

  if (!teams) {
    return <React.Fragment/>;
  }

  return <LeaderBoard title={`Last Event - ${weekend?.weekend_name || ''}`} elements={teams} />
}
