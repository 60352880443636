import React from "react";
import {
    createBrowserRouter,
    Link,
    Params,
    redirect,
    RouteObject,
    useInRouterContext,
    useRouteError
} from "react-router-dom";
import {
    LeaguesApp,
    LeagueWeekendView,
    SingleLeaguePage
} from './LeaguesApp';
import { RegisterUserPage } from './RegisterUser';
import { MyUserPage, UserPage } from './UserApp';
import DefaultAthletesApp, { AthleteView } from './AthleteApp';
import { IndexPage } from "./IndexPage";
import { OverallPage } from "./OverallApp";
import { WeekendPage, WeekendsPage } from "./WeekendsPage";
import { LoginUserPage } from './LoginUser';
import { ForgotPasswordPage } from './ForgotPassword';
import { AthleteTable, RacePage } from "./RaceApp";
import {
    ApplicationComponentProvider,
    CountryProvider,
    CupProvider, ErrorStateProvider,
    SettingsProvider,
    UserProvider
} from "./Provider";
import { PageNotFound, ServerError } from "./ErrorPages";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { ErrorResponse } from "./Util";
import { LoaderFunction } from "@remix-run/router/utils";
import {
    athleteLoader,
    leagueWeekendLoader,
    raceLoader,
    singleLeagueLoader,
    userLoader,
    weekendLoader
} from "./loaders";

export const ApplicationRouting = ({data}: { data: Routes }) => {

    let routes: RouteObject[] = [
        {
            path: "",
            ...Routes.routeToData(data.getIndexPage())
        },
        {
            path: "races",
            children: [
                {
                    path: ":raceId",
                    ...Routes.routeToData(data.getRacePage())
                }
            ]
        },
        {
            path: "race",
            children: [
                {
                    path: ":raceId",
                    loader: ({ params }) => {
                        return redirect(`/races/${params.raceId}`)
                    }
                }
            ]
        },
        {
            path: "login",
            ...Routes.routeToData(data.getLoginpage())
        },
        {
            path: "register",
            ...Routes.routeToData(data.getRegisterView())
        },
        {
            path: "reset",
            children: [
                {
                    index: true,
                    ...Routes.routeToData(data.getForgotPassword())
                },
                {
                    path: ":resetId",
                    ...Routes.routeToData(data.getResetPassword())
                },
            ]
        },
        {
            path: "athletes",
            children: [
                {
                    index: true,
                    ...Routes.routeToData(data.getAthletesElement())
                },
                {
                    path: ":athleteId",
                    ...Routes.routeToData(data.getAthletePage())
                },
            ]
        },
        {
            path: "leagues",
            children: [
                {
                    index: true,
                    ...Routes.routeToData(data.getLeaguesElement())
                },
                {
                    path: ":leagueName",
                    children: [
                        {
                            ...Routes.routeToData(data.getSingleLeagueElement()),
                            index: true
                        },
                        {
                            path: "weekend/:weekendId",
                            ...Routes.routeToData(data.getLeagueWeekendView()),
                        },
                    ]
                },
            ]
        },
        {
            path: "user",
            children: [
                {
                    path: "me",
                    loader: () => {
                        return redirect(`/users/me`)
                    }
                },
                {
                    path: ":userId",
                    loader: ({ params }) => {
                        return redirect(`/users/${params.userId}`)
                    }
                }
            ]
        },
        {
            path: "users",
            children: [
                {
                    path: "me",
                    ...Routes.routeToData(data.getMyUserPage()),
                },
                {
                    path: ":userId",
                    ...Routes.routeToData(data.getUserPage()),
                }
            ]
        },
        {
            path: "overall",
            ...Routes.routeToData(data.getOverallView()),
        },
        {
            path: "events",
            children: [
                {
                    index: true,
                    ...Routes.routeToData(data.getEventsPage()),
                },
                {
                    path: ":weekendId",
                    ...Routes.routeToData(data.getEventPage()),
                },
            ]
        },
        {
            path: "*",
            ...Routes.routeToData(data.get404Page())
        }
    ];
    routes = routes.concat(data.appSpecificViews())
    let rootRoutes: RouteObject[] = [
        {
            element: <ErrorStateProvider>
                <SettingsProvider>
                    <CountryProvider>
                        <CupProvider>
                            <UserProvider>
                                {data.appSpecificProviders(data.getBaseElement())}
                            </UserProvider>
                        </CupProvider>
                    </CountryProvider>
                </SettingsProvider>
            </ErrorStateProvider>,
            children: [
                {
                    errorElement: <ErrorPage />,
                    children: routes
                }
            ]
        }
    ];
    rootRoutes = rootRoutes.concat(data.extraRoutes());
    return createBrowserRouter(rootRoutes);
}

const ErrorPage = () => {
    const error = useRouteError() as ErrorResponse<any>;
    if(error.response?.status === 404) {
        return <PageNotFound />;
    }
    return <ServerError />;
}

export const ApplicationLink = (link: React.PropsWithChildren<LinkData>) => {
    const inRouterContext = useInRouterContext();
    if(link.href) {
        const url = new URL(link.href, window.location.href);
        const isInternalLink = url.host === window.location.host;
        if(inRouterContext && isInternalLink) {
            return <Link className={link.class} to={link.href}>{link.children}</Link>;
        }
    }
    return <a className={link.class} href={link.href} target="_blank" rel="noreferrer">{link.children}</a>
};

export interface LinkData {
    href?: string;
    class?: string;
}

export interface ApplicationRoute {
    path?: string;
    element?: React.JSX.Element;
    children: ApplicationRoute[];
    loader?: ({params}: {params: Params<string>}) => void;
}

export abstract class Routes {

    abstract getBaseElement(): React.JSX.Element;

    getIndexPage(): React.JSX.Element | Route {
        return <IndexPage />;
    }

    getLeaguesElement(): React.JSX.Element | Route {
        return <LeaguesApp includeTeamChallenge={false}/>;
    }

    getSingleLeagueElement(): React.JSX.Element | Route {
        return {
            element: <SingleLeaguePage/>,
            loader: singleLeagueLoader
        }
    }

    getAthletesElement(): React.JSX.Element | Route {
        return <DefaultAthletesApp/>;
    }

    getUserPage(): React.JSX.Element | Route {
        return {
            element: <UserPage />,
            loader: userLoader
        }
    }

    getMyUserPage(): React.JSX.Element | Route {
        return <MyUserPage />;
    }

    getLoginpage(): React.JSX.Element | Route {
        return <LoginUserPage />;
    }

    getForgotPassword(): React.JSX.Element | Route {
        return <ForgotPasswordPage />;
    }

    getResetPassword(): React.JSX.Element | Route {
        return <ResetPasswordPage />;
    }

    getAthletePage(): React.JSX.Element | Route {
        return {
            element: <AthleteView />,
            loader: athleteLoader
        }
    }

    getEventsPage(): React.JSX.Element | Route {
        return <WeekendsPage />;
    }

    getEventPage(): React.JSX.Element | Route {
        return {
            element: <WeekendPage />,
            loader: weekendLoader
        }
    }

    getRegisterView(): React.JSX.Element | Route {
        return <RegisterUserPage />;
    }

    getOverallView(): React.JSX.Element | Route {
        return <OverallPage />
    }

    getLeagueWeekendView(): React.JSX.Element | Route {
        return {
            element: <LeagueWeekendView />,
            loader: leagueWeekendLoader
        }
    }

    getRacePage(): React.JSX.Element | Route {
        return {
            element: <RacePage athleteTable={(race,
                                              selection,
                                              weekend) =>
                <AthleteTable race={race} selection={selection} weekend={weekend} />} />,
            loader: raceLoader
        }
    }

    get404Page(): React.JSX.Element | Route {
        return <PageNotFound />
    }

    appSpecificProviders(children: React.JSX.Element) {
        return <ApplicationComponentProvider>
            { children }
        </ApplicationComponentProvider>
    }

    appSpecificViews(): RouteObject[] {
        return [];
    }

    extraRoutes(): RouteObject[] {
        return [];
    }

    static routeToData(route: Route | React.JSX.Element): Route {
        if("element" in route) {
            return {
                loader: route.loader,
                element: route.element
            }
        }
        return {
            element: route as React.JSX.Element
        }
    }
}

interface Route {
    element: React.JSX.Element | React.JSX.Element[];
    loader?: LoaderFunction;
}
