import React, { useContext, useEffect, useMemo, useState } from "react"
import {
    ApplicationComponents,
    ApplicationLink,
    Athlete,
    AthletePopularity,
    AthletePrice,
    Components,
    HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER,
    Race,
    Table,
    Weekend
} from "@fantasy/components";
import { CupScores, OwnedVismaAthlete, VismaAthlete, VismaWeekend } from "./models";
import { UserTable, WeekendInformationTable } from "@fantasy/components/src/components/WeekendsPage.tsx";
import "./scss/weekends.scss";
import { useLoaderData } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
    formatNullableAmount,
    formatNumber,
    formatRank,
    formatScore
} from "@fantasy/components/src/components/Util.ts";

const EventList = ({title, events}: {title: string, events: VismaWeekend[]}) => {
    return <>
        <h2>{title}</h2>
        { events.map((event) => <Weekend weekend={event} />) }
    </>
}

export const WeekendsPage = () => {
    const [weekends, setWeekends] = useState<VismaWeekend[] | undefined>(undefined);

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get<VismaWeekend[]>({
            url: '/api/weekends?season=current'
        }).then((weekends: VismaWeekend[]) => {
            weekends = weekends.sort((a, b) => a.event_start.localeCompare(b.event_start));
            setWeekends(weekends);
        });
    }, []);

    if(!weekends) {
        return <React.Fragment />;
    }
    const isUpcoming = (weekend: VismaWeekend) => new Date(weekend.event_start) > new Date();
    const upcomingEvents = weekends.filter((weekend) => isUpcoming(weekend));
    const pastEvents = weekends.filter((weekend) => !isUpcoming(weekend));
    return <div>
        <header className="entry-header">
            <h1 className="entry-title">Ski Classics Events</h1>
        </header>
        { upcomingEvents && <EventList title="Upcoming Events" events={upcomingEvents} /> }
        { pastEvents && <EventList title={"Past Events"} events={pastEvents} /> }
    </div>
}

const RaceList = ({races}: {races: Race[]}) => (
    <div className="race-list">
        { races.map((race) => (
            <div className="race-item">
                <h4>
                    { race.name }, { race.distance }
                </h4>
                <span className="fi-calendar"></span>  { race.date }
            </div>
        )) }
    </div>
)

const WeekendDateInfo = ({weekend}: {weekend: VismaWeekend}) => (
    <>
        <span className="fi-calendar"></span> { weekend.event_start } { weekend.event_start !== weekend.event_end ? `- ${weekend.event_end}` : '' }
    </>
)

const Weekend = ({weekend}: {weekend: VismaWeekend}) => {
    const races = weekend.selections.reduce((array: Race[], selection) => {
        array.push(selection.races[0])
        return array;
    }, []);

    let livestreamLink;
    let eventLink;

    if(weekend.livestream_link) {
        livestreamLink = <a href={ weekend.livestream_link } target="_blank" rel={"noopener noreferrer"}>
            <i className="fi-play-video"/> Click Here to Stream
        </a>
    }

    if(weekend.event_link) {
        eventLink = <a href={ weekend.event_link } target="_blank" rel={"noopener noreferrer"}>
            <i className="fi-info"/> Ski Classics Event Details (opens in a new tab)
        </a>;
    }

    return <div className="event-item clearfix">
        <h3>
            <ApplicationLink class="link" href={ `/events/${weekend.weekend_id }` }>
                { weekend.weekend_name } ({ races[0].country })
                { races.length === 1 && `, ${races[0].distance}` }
            </ApplicationLink>
        </h3>
        <div className="columns medium-8">
            { races.length > 1 ? <RaceList races={races} /> : <WeekendDateInfo weekend={weekend} /> }
        </div>

        <div className='columns medium-4'>
            <p>{ eventLink }</p>
            <p>{ livestreamLink }</p>
        </div>
    </div>
}

export const WeekendPage = () => {

    const data = useLoaderData() as [Weekend, CupScores[]];
    const weekend = data[0];

    return <>
        <h1>{ weekend.weekend_name || <Skeleton/> }</h1>
        <div className="row">
            <div className="medium-6 columns">
                <WeekendInformationTable weekend={weekend} />
            </div>
            <div className="medium-6 columns">
                <UserTable weekend={weekend} />
            </div>
        </div>
        <div className="row">
            <div className="medium-12 columns">
                <AthleteTable weekend={weekend} cupScores={data[1]} />
            </div>
        </div>
    </>
}
const AthleteTable = ({ weekend, cupScores }: { weekend: Weekend, cupScores: CupScores[]}) => {
    const [usersTeam, setUsersTeam] = useState<OwnedVismaAthlete[]>([]);
    const [bestAthletes, setBestAthletes] = useState<VismaAthlete[] | undefined>();
    const [athletePrices, setAthletePrices] = useState<Map<number, number> | undefined>();
    const [athletePopularities, setAthletePopularities] = useState<Map<number, number> | undefined>();
    const {athleteDisplay} = useContext<Components>(ApplicationComponents);
    const cupScorePerAthlete = cupScores.reduce((map, score) => map.set(score.athlete.athlete_id, score), new Map<number, CupScores>());

    useEffect(() => {
        if(weekend) {
            HTTP_CLIENT.get<VismaAthlete[]>(`/api/weekends/${weekend.weekend_id}/results/athletes`)
                .then((race) => {
                    setBestAthletes(race.data)
                });

            weekend.selections.forEach((selection) => {
                HTTP_CLIENT.get<OwnedVismaAthlete[]>(`/api/me/teams/selections/${selection.selection_id}`)
                    .then((team) => {
                        setUsersTeam((currentTeam) => [...currentTeam].concat(team.data))
                    });
            });

            if (!weekend.trading_open && weekend.selections.length === 1) {
                HTTP_CLIENT.get<AthletePopularity[]>(weekend.selections[0].links.athlete_popularity)
                    .then((response) => {
                        const athletePopularities = response.data.reduce((selectionPopularities, popularity) =>
                            selectionPopularities.set(popularity.athlete_id, popularity.ratio), new Map<number, number>());
                        setAthletePopularities(athletePopularities);

                    });
            }

            HTTP_CLIENT.get<AthletePrice[]>(weekend.links.prices)
                .then((response) => {
                    const weekend_prices = response.data.reduce((prices, price) =>
                        prices.set(price.athlete_id, price.price), new Map<number, number>());
                    setAthletePrices(weekend_prices);
                });
        }
    }, [weekend]);

    function calculatePPG(athlete: Athlete): number | string {
        const athletePrice = athletePrices?.get(athlete.athlete_id);
        if(!athletePrice) {
            return '∞'
        }
        return formatNumber(Math.round((athlete.score * 100000 / athletePrice)) / 100.0);
    }

    const bestQciAthlete = useMemo(() => {
        const ownedQciAthletes = usersTeam.filter((athlete) => athlete.athlete.is_qci_bonus_eligible).map((athlete) => athlete.athlete.athlete_id);
        if (!ownedQciAthletes.length) {
            return undefined;
        }
        const sortedCupScores = cupScores.sort((a, b) => a.champion - b.champion);
        return sortedCupScores.find((cupScore) => ownedQciAthletes.includes(cupScore.athlete.athlete_id))
    }, [cupScores, usersTeam]);

    const columns = [
        {
            dataFunction: (data: Athlete) => { return {
                content: formatRank(data.rank),
            }},
            header: '',
            headerKey: 'athlete rank'
        },
        {
            dataFunction: (data: Athlete) => { return {
                content: athleteDisplay(data),
            }},
            header: 'Name',
            headerKey: 'athlete name'
        },
        {
            dataFunction: (data: Athlete) => {
                let qciScore = undefined;
                if (data.athlete_id === bestQciAthlete?.athlete_id) {
                    qciScore = bestQciAthlete.champion / 2;
                }
                return {
                content: formatScore(data.score) + (qciScore ? ` (+${formatScore(qciScore)} QCI Bonus)` : ''),
            }},
            header: 'Weekend score'
        },
        {
            dataFunction: (data: Athlete) => { return {
                content: formatNullableAmount(athletePrices?.get(data.athlete_id),  "."),
            }},
            header: 'Price'
        },
        {
            dataFunction: (athlete: Athlete) => { return {
                isLoading: athletePrices === undefined,
                content: calculatePPG(athlete),
            }},
            header: 'PPG'
        },
        {
            dataFunction: (athlete: Athlete) => {
                const athleteInTeamCount = usersTeam?.filter((userAthlete) => userAthlete.athlete.athlete_id === athlete.athlete_id).length;
                const matchingSelections = weekend?.selections.filter(
                    (selection) => selection.takes_team_picks === athlete.is_team && selection.is_coed === (athlete.gender === 'mixed')).length

                let inMyTeamString;

                if (athleteInTeamCount) {
                    inMyTeamString =  '✔';

                    if (matchingSelections && matchingSelections > 1) {
                        inMyTeamString += ` (${athleteInTeamCount}/${matchingSelections})`;
                    }
                } else {
                    inMyTeamString = ' ';
                }

                return {
                    content: inMyTeamString,
                }
            },
            header: 'In my team?'
        }
    ];

    if(weekend?.selections.find((selection) => selection.team_captains_enabled)) {
        columns.push(
            {
                dataFunction: (athlete: Athlete) => { return {
                    isLoading: usersTeam === undefined,
                    content: usersTeam?.find((userAthlete) => userAthlete.athlete.athlete_id === athlete.athlete_id && userAthlete.is_team_captain) ? '✔' : ' ',
                }},
                header: 'Captain'
            })
    }

    if (weekend && weekend?.selections.length === 1) {
        columns.push(
            {
                dataFunction: (athlete: Athlete) => ({
                    isLoading: false,
                    content: `${Math.round((athletePopularities?.get(athlete.athlete_id) || 0) * 100)}%`
                }),
                header: 'Popularity'
            }
        )
    }

    return <React.Fragment>
        <h2>Athlete results</h2>
        <Table columns={columns} data={bestAthletes} config={{loadingRows: 30}} />
    </React.Fragment>
}
