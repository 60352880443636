import React, { HTMLInputTypeAttribute, useContext, useEffect, useRef, useState } from "react";
// @ts-expect-error the type is there
import { UseFormRegister, UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { CountryContext, CountryData } from "./Context";
import "./scss/loading.scss"
import { usePopper } from "react-popper";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import { HTTP_CLIENT } from "./Util";
import { ApplicationLink } from "./Routing";

export const FantasyModal = ({isOpen, close, title, message}: {isOpen: boolean, close: (accepted: boolean) => void, title: string, message: string}) => {
    if(!isOpen) {
        return <></>
    }
    return <div className="reveal-overlay" style={{"display": "block"}}>
        <div className="reveal" data-reveal="fq9tik-reveal" role="dialog" aria-hidden="false"
             tabIndex={-1} style={{"display": "block", "top": "81px"}} data-events="resize">
            <h3 id="question-title">{title}</h3>
            <p className="lead question-message" style={{fontSize: '14px'}}>{message}</p>
            <button className="button float-right" onClick={() => close(false)}>No</button>
            <button className="button yes float-right" data-close="" style={{marginRight: '0.5em'}} onClick={() => close(true)}>Yes</button>
            <button className="close-button" aria-label="Close modal" type="button" onClick={() => close(false)}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
}

export const TextField = ({ registerFields, type, disabled, hidden, className, placeholder }: { registerFields: UseFormRegisterReturn,
    label?: string, type?: HTMLInputTypeAttribute, disabled?: boolean, hidden?: boolean, className?: string, placeholder?: string }) => {
    className = className + (hidden ? " hide" : "");
    return <input type={type || "text"} className={className}
                  {...registerFields }
                  placeholder={placeholder}
                  disabled={disabled} />
}

export const EmailField = ({register, disabled}: {register: UseFormRegisterReturn, disabled?: boolean}) => {
    return <TextField registerFields={register} label={"Email"} disabled={disabled} />;
}

export const UsernameField = ({register, disabled}: {register: UseFormRegisterReturn, disabled?: boolean}) => {
    return <TextField registerFields={register} label={"Username"} disabled={disabled} />;
}

export const FieldContainer = ({children, error, label}: {children: React.JSX.Element, error?: string, label?: string}) => {
    return LabeledFieldContainer({children, error, label: label ? <label htmlFor={label}>{label}</label> : undefined})
}

export const LabeledFieldContainer = ({children, error, label}: {children: React.JSX.Element, error?: string, label?: React.JSX.Element}) => {
    return <>
        { label }
        { children }
        { error && <span className="form-error is-visible" style={{marginBottom: '0.5rem'}}>
                {error}
            </span> }
    </>
}

export const CheckboxField = ({registerFields, label, error}: {registerFields: UseFormRegisterReturn, label?: string | React.JSX.Element, error?: string}) => {
    return <div>
        <label>
            <input type="checkbox" { ...registerFields } />
            {label}
        </label>
        { error && <span className="form-error is-visible" style={{marginBottom: '0.5rem'}}>
                {error}
            </span> }
    </div>
}

export const CountryDropdown = ({register, fieldName, className}: { register: UseFormRegister<any>, fieldName: string, className?: string}) => {
    const countryData = useContext<CountryData>(CountryContext);

    const countries = (countryData.countries || []).map((country) => <option key={country.alpha_3} value={country.alpha_3}>{country.name}</option>);

    const validation = {
        required: {
            value: true,
            message: "You must select a country"
        }};

    return <select {...register(fieldName, validation)} className={className}>
        <option key={"default"}>----</option>
        {countries}
    </select>;
}

export const Message = ({text}: {text: string}) => {
    const [dismissed, setDismissed] = useState<boolean>(false);
    if(dismissed) {
        return <></>;
    }

    return <ul className="flashes">
        <div className="alert callout">
            {text}
            <button className="close-button" aria-label="Dismiss alert" type="button" onClick={() => setDismissed(true)}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </ul>
}

export const Accordion = ({children}: {children: React.JSX.Element[]}) => {
    return <ul className={"accordion"}>
        { children }
    </ul>
}

interface AccordionObjectProps {
    name: string | React.JSX.Element,
    active: boolean,
    children: string | React.JSX.Element | React.JSX.Element[],
    select?: () => void
}

export const AccordionObject = ({name, active, children, select}: AccordionObjectProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [paddingHeight, setPaddingHeight] = useState<number>(0);

    useEffect(() => {
        if(ref.current) {
            setPaddingHeight(ref.current.clientHeight);
        }
    }, []);

    let className = "accordion-item";
    let paddingStyle;
    let expandLogo;
    if(active) {
        className += " is-active";
        expandLogo = <i className="expand-icon fi-minus"></i>;
        paddingStyle = {maxHeight: paddingHeight + "px", marginTop: (-paddingHeight) + "px"}
    }
    else {
        expandLogo = <i className="expand-icon fi-plus"></i>;
        paddingStyle = {maxHeight: 0}
    }

    return <li className={className}>
        <div className={"accordion-title"} onClick={() => select?.()}>
            <span className={"title-content"}>{ name }</span>
            { expandLogo }
        </div>
        <div ref={ref} className={"accordion-content"}>{ children }</div>
        <div className={"accordion-padding"} style={ paddingStyle }></div>
    </li>
}

export const LoadingIndicator = ({children}: {children: JSX.Element}) => {
    return <div className={"loading"}>
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        {children}
    </div>
}

export const TooltipComponent = ({text, onClick}: {text: string, onClick?: () => void}) => {
        const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
        const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
        const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
        const { styles, attributes } = usePopper(referenceElement, popperElement, {
            modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
            placement: "top"
        });
    return <div className="tooltip-container" onClick={onClick} ref={setReferenceElement}>
        <span ref={setPopperElement} style={styles.popper} {...attributes.popper}
          className="tooltip-text">{text}
        <div ref={setArrowElement} style={styles.arrow}/>
        </span>
    </div>
}

export const CopyPage = () => {

    const location = useLocation();

    const [copy, setCopy] = useState<string | undefined>();

    useEffect(() => {
        HTTP_CLIENT.get<string>(`/api/content/${location.pathname}/payload`)
            .then((response) => setCopy(response.data))
    }, [location.pathname])
    if(!copy) {
        return <Skeleton />
    }
    return <ReactMarkdown components={{
        a: ({...props}) => <ApplicationLink href={props.href}>{props.children}</ApplicationLink>
    }}>{copy}</ReactMarkdown>
}
