import { useLoaderData } from "react-router-dom";
import React from "react";
import { ResultTable } from "@fantasy/components/src/components/AthleteApp";
import { VismaAthlete } from "./models";

export const VismaAthletePage = () => {
    const athlete = useLoaderData() as VismaAthlete;

    return <div>
        <h1 className="entry-title">{ athlete.name }</h1>
        <p><img style={{height: '3em'}} src={athlete.pro_team?.logo_url} alt={""}/> {athlete.pro_team?.name }</p>
        <div className="row">
            <div className="medium-6 columns">
                <h2>Athlete information</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Score:</td>
                            <td>{athlete.score || '-' }</td>
                        </tr>
                        <tr>
                            <td>Rank:</td>
                            <td>{athlete.rank || 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>Gender:</td>
                            <td>{athlete.gender === 'm' ? 'Male' : 'Female'}</td>
                        </tr>
                        <tr>
                            <td>Price:</td>
                            <td>${athlete.price}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ResultTable athleteId={athlete.athlete_id} />
        </div>
    </div>
}
