import React, { useContext, useEffect, useState } from "react"
import { ApplicationComponents, Components, Context, UserContext } from "./Context";
import { RankedUser, WeekendResults } from "./models";
import { HTTP_CLIENT_JQUERY_ADAPTER } from "./Util";
import { ApplicationLink } from "./Routing";

export const IndexPage = () => {
    const context = useContext<Context>(UserContext);
    if(!context.loggedInState.loggedIn === undefined) {
        return <React.Fragment />
    }
    let loginLinks;
    if (!context.loggedInState.loggedIn) {
        loginLinks = <div className="intro">
            <p>If you've played before, <ApplicationLink href={'/login'}>login here</ApplicationLink>.</p>
            <p>If you're new, <ApplicationLink href={'/register'}>register here</ApplicationLink>.</p>
        </div>;
    }
    return <div>
        <h1>Welcome! Velkommen! Välkommen!</h1>
        {loginLinks}
        <h2 className="entry-title">Overall Leaderboard</h2>
        <div className="row">
            <div className="medium-6 columns">
                <TopTeams />
            </div>
            <div className="medium-6 columns">
                <LastWeekend />
            </div>
        </div>
    </div>
}

export const TopTeams = () => {

    const [teams, setTeams] = useState<RankedUser[]>([]);

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/cups/Overall/leaderboard?limit=10',
            success: (teams: RankedUser[]) => {
                setTeams(teams);
            }
        });
    }, []);

    if (!teams) {
        return <React.Fragment/>;
    }

    return <LeaderBoard title={'Season Leaders'} elements={teams} />;
}

const LastWeekend = () => {

    const [teams, setTeams] = useState<RankedUser[]>([]);
    const [weekendName, setWeekendName] = useState<string>('');

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: '/api/weekends/previous/results/users',
            success: (results: WeekendResults) => {
                setTeams(results.users);
                setWeekendName(results.weekend.weekend_name)
            }
        });
    }, []);

    if (!teams) {
        return <React.Fragment/>;
    }

    return <LeaderBoard title={'Last Weekend - ' + weekendName} elements={teams} />;
}

export const LeaderBoard = ({title, elements}: { title: string, elements: RankedUser[] }) => {
    return <>
        <h4>{title}</h4>
        <LeaderboardTable data={elements} />
    </>
}

const LeaderboardTable = ({data}: { data: RankedUser[] }) => {
    const {userDisplay} = useContext<Components>(ApplicationComponents);
    const rows = data.map((row) => <tr key={row.user_id}>
        <td>{row.rank}.</td>
        <td>{userDisplay(row.user)}</td>
        <td>{row.score} p.</td>
    </tr>) || <tr>
        <td colSpan={3}><i>No results registered yet</i></td>
    </tr>;

    return <table className={"leaderboard"}>
        <thead>
            <tr>
                <th/>
                <th>Name</th>
                <th>Score</th>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table>
}
