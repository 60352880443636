import React, { useContext } from "react";
import { Link, MainMenu } from "@fantasy/components";
import { Context, UserContext } from "@fantasy/components";

export const VismaMainMenu = () => {
    const context = useContext<Context>(UserContext);
    if(context.loggedInState.loggedIn === undefined) {
        return <MainMenu links={[]} />
    }
    const links: Link[] = [
        {
            url: '/',
            caption: 'Leaderboards',
        },
        {
            url: '/info/rules',
            caption: 'How to play'
        },
        {
            url: '/athletes',
            caption: 'Team Selection'
        },
        {
            url: '/leagues',
            caption: 'Leagues'
        },
        {
            url: '/events',
            caption: 'Events'
        },
        {
            url: '/info/contact',
            caption: 'Contact'
        },
    ];
    if(!context.loggedInState.loggedIn) {
        links.push(
            {
                url: '/login',
                caption: 'Log in'
            }
        );
    }

    return <MainMenu links={links} />;
};
